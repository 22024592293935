<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/admin/professores?secretaria'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/admin/professores?secretaria')"
              >Gerenciamento de professores</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Professor</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Dados do
                  <span>Professor</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <tabs :options="{ useUrlFragment: false }">
                    <!-- Dados pessoais -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Dados pessoais"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Dados pessoais</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div v-if="fastPessoa.loading" class="col-12 text-center">
                              <b-icon
                                icon="gear-fill"
                                animation="spin"
                              /> Carregando dados
                            </div>
                            <div v-else class="col-12 p-4">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <h6>Informações pessoais</h6>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Primeiro nome</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.nome_razao"
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Sobrenome</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.sobrenome_fantasia"
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Email</label>
                                  <div class="form-group">
                                    <input
                                      :value="fastPessoa.email"
                                      class="form-control"
                                      type="text"
                                      readonly
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Data de nascimento</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPessoa.datanascimento_abertura
                                      "
                                      class="form-control"
                                      type="date"
                                      @focus="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>CPF</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.cpf_cnpj"
                                      v-mask="[
                                        '###.###.###-##',
                                        '##.###.###/####-##',
                                      ]"
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true;
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Sexo</label>
                                  <div class="form-group">
                                    <select
                                      v-model="fastPessoa.sexo"
                                      class="form-control"
                                      @change="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                      <option value="">
                                        Indefinido
                                      </option>
                                      <option value="M">
                                        Masculino
                                      </option>
                                      <option value="F">
                                        Feminino
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Telefone</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.telefone"
                                      v-mask="[
                                        '(##) ####-####',
                                        '(##) #####-####',
                                      ]"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <h6>Endereço</h6>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>CEP</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.cep"
                                      v-mask="'#####-###'"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true;
                                        buscaCEPProfessor();
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Endereço</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.logradouro"
                                      type="text"
                                      class="form-control"
                                      maxlength="100"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Número</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.numero"
                                      type="text"
                                      class="form-control"
                                      maxlength="10"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Complemento</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.complemento"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Bairro</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.bairro"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Cidade</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.cidade"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Estado</label>
                                  <div class="form-group">
                                    <input
                                      v-model="fastPessoa.uf"
                                      type="text"
                                      class="form-control"
                                      maxlength="2"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="row text-center">
                                <div
                                  v-if="fastPessoa.alteracao_pendente"
                                  class="col-12 text-center"
                                >
                                  <button
                                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                    @click="alteraPlataformaPessoa()"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-floppy-o"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Salvar alterações
                                    </small>
                                  </button>
                                  <button
                                    class="btn btn-secondary btn-sm mt-2 ml-2"
                                    @click.prevent="
                                      buscaInfoPessoa(
                                        $route.params.id_pessoa_aluno
                                      )
                                    "
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Cancelar
                                    </small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Dados do professor -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Dados do professor"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 m-2">
                              <h4>Dados do professor</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="fastPlataformaProfessor.carregando"
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12 p-4"
                            >
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Nome</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessor.first_name
                                      "
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPlataformaProfessor.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Sobrenome</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessor.last_name
                                      "
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPlataformaProfessor.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Email</label>
                                  <div class="form-group">
                                    <input
                                      :value="fastPlataformaProfessor.email"
                                      class="form-control"
                                      type="text"
                                      readonly
                                    >
                                  </div>
                                </div>
                                <div class="col-12 mb-4">
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-4">
                                      <label>Imagem atual</label>
                                      <div class="form-group">
                                        <img
                                          :src="fastPlataformaProfessor.image"
                                          style="max-width: 100%"
                                        >
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-4">
                                      <label>Trocar imagem</label>
                                      <vue-cropper
                                        @image="fastProfessorImageNova = $event"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Formação</label>
                                  <div class="form-group">
                                    <select
                                      v-model="
                                        fastPlataformaProfessor.licenciatura
                                      "
                                      class="form-control"
                                      @change="
                                        fastPlataformaProfessor.alteracao_pendente = true
                                      "
                                    >
                                      <option value="0">
                                        Nenhuma
                                      </option>
                                      <option value="1">
                                        Graduação
                                      </option>
                                      <option value="2">
                                        Especialização
                                      </option>
                                      <option value="3">
                                        Mestrado
                                      </option>
                                      <option value="4">
                                        Doutorado
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div
                                v-if="
                                  fastPlataformaProfessor.alteracao_pendente ||
                                    fastProfessorImageNova !=
                                    fastPlataformaProfessor.image
                                "
                                class="row"
                              >
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 m-2 text-center"
                                >
                                  <button
                                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                    @click="atualizaPlataformaProfessor()"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-floppy-o"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Salvar alterações
                                    </small>
                                  </button>
                                  <button
                                    class="btn btn-secondary btn-sm mt-2 ml-2"
                                    @click.prevent="
                                      getFastPlataformaProfessor(
                                        $route.params.id_plataforma,
                                        $route.params.id_pessoa
                                      )
                                    "
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Cancelar
                                    </small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Documentos -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Documentos"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Documentos</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="fastPlataformaProfessorDocumento.carregando"
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12"
                            >
                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 text-right"
                                >
                                  <button
                                    v-if="fastSecretariaTipoDocumentos.length"
                                    class="btn btn-sm btn-primary"
                                    @click.prevent="
                                      showModal(
                                        'modalCadastrarProfessorDocumento'
                                      )
                                    "
                                  >
                                    <small>+ Documento</small>
                                  </button>
                                  <div v-else>
                                    <small
                                      class="text-danger"
                                    >Você não possui nenhum tipo de documento
                                      cadastrado na plataforma</small>
                                    <br>
                                    <a href="">Cadastrar documentos</a>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 table-responsive p-4">
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th>Tipo documento</th>
                                        <th class="text-center">
                                          Link
                                        </th>
                                        <th class="text-center">
                                          Ações
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      v-if="
                                        fastPlataformaProfessorDocumentos.length
                                      "
                                    >
                                      <tr
                                        v-for="(
                                          d, index
                                        ) in fastPlataformaProfessorDocumentos"
                                        :key="index"
                                      >
                                        <td>
                                          {{ d.nome_documento }}
                                        </td>
                                        <td class="text-center">
                                          <a
                                            :href="d.path_documento"
                                            target="_blank"
                                            download
                                          >{{ d.path_documento }}</a>
                                        </td>
                                        <td class="text-center">
                                          <button
                                            class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                            @click="
                                              exibeModalExcluirProfessorDocumento(
                                                d
                                              )
                                            "
                                          >
                                            <small
                                              class="text-white"
                                            >Excluir</small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="3"
                                          class="text-center"
                                        >
                                          <span>Nenhum documento cadastrado</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Dados do professor -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Disciplinas"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 m-2">
                              <h4>Disciplinas do professor</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="
                                fastPlataformaProfessorDisciplina.carregando
                              "
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12"
                            >
                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 text-right"
                                >
                                  <button
                                    class="btn btn-sm btn-primary"
                                    @click.prevent="
                                      showModal('modalEscolherDisciplina')
                                    "
                                  >
                                    <small>+ Disciplina</small>
                                  </button>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 table-responsive p-4">
                                  <table
                                    class="table table-striped table-bordered"
                                  >
                                    <thead>
                                      <tr class="bg-dark text-white">
                                        <th scope="col">
                                          Nome da disciplina
                                        </th>
                                        <th
                                          class="text-center"
                                          scope="col"
                                        >
                                          Opções
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      v-if="
                                        fastPlataformaProfessorDisciplinas.length
                                      "
                                    >
                                      <tr
                                        v-for="disciplina in fastPlataformaProfessorDisciplinas"
                                        :key="
                                          disciplina.id_plataforma_area_disciplina
                                        "
                                      >
                                        <td>{{ disciplina.descricao }}</td>
                                        <td class="text-center">
                                          <button
                                            class="btn btn-danger btn-sm mt-2 ml-2"
                                            @click="
                                              excluirDisciplinaProfessor(
                                                disciplina
                                              )
                                            "
                                          >
                                            <small>
                                              <i
                                                class="fa fa-ban"
                                                aria-hidden="true"
                                              />
                                              &nbsp; Excluir
                                            </small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="2"
                                          class="text-center"
                                        >
                                          <h6>Nenhuma disciplina encontrada</h6>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Dados do professor -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Financeiro"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 m-2">
                              <h4>Dados financeiros</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="
                                fastPlataformaProfessorFinanceiro.carregando
                              "
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12 p-4"
                            >
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Valor hora</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessorFinanceiro.valor_hora
                                      "
                                      class="form-control"
                                      type="number"
                                      step=".1"
                                      @keyup="
                                        fastPlataformaProfessorFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Salário CLT bruto</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessorFinanceiro.salario_clt_bruto
                                      "
                                      class="form-control"
                                      type="number"
                                      step=".1"
                                      @keyup="
                                        fastPlataformaProfessorFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Contrato</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessorFinanceiro.contrato
                                      "
                                      class="form-control"
                                      type="text"
                                      maxlength="10"
                                      @keyup="
                                        fastPlataformaProfessorFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Valor do contrato</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessorFinanceiro.valor_contrato
                                      "
                                      class="form-control"
                                      type="number"
                                      step=".1"
                                      @keyup="
                                        fastPlataformaProfessorFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Data de início</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessorFinanceiro.data_inicio
                                      "
                                      class="form-control"
                                      type="date"
                                      @keyup="
                                        fastPlataformaProfessorFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <label>Data de encerramento</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaProfessorFinanceiro.data_encerramento
                                      "
                                      class="form-control"
                                      type="date"
                                      @keyup="
                                        fastPlataformaProfessorFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div
                                        class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                      >
                                        <div
                                          class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                        >
                                          <div class="col-12 text-center mb-1">
                                            <span>Ativo</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input
                                                v-model="
                                                  fastPlataformaProfessorFinanceiro.ativo
                                                "
                                                type="checkbox"
                                                @change="
                                                  fastPlataformaProfessorFinanceiro.alteracao_pendente = true
                                                "
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                v-if="
                                  fastPlataformaProfessorFinanceiro.alteracao_pendente
                                "
                                class="row"
                              >
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 m-2 text-center"
                                >
                                  <button
                                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                    @click="
                                      atualizaPlataformaProfessorFinanceiro()
                                    "
                                  >
                                    <small>
                                      <i
                                        class="fa fa-floppy-o"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Salvar alterações
                                    </small>
                                  </button>
                                  <button
                                    class="btn btn-secondary btn-sm mt-2 ml-2"
                                    @click.prevent="
                                      getFastPlataformaProfessorFinanceiro(
                                        $route.params.id_plataforma,
                                        $route.params.id_professor
                                      )
                                    "
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Cancelar
                                    </small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/admin/professores?secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/professores?secretaria')"                 
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCadastrarProfessorDocumento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cadastrar documento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarProfessorDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Tipo de documento</label>
              <div class="form-group">
                <select
                  v-model="fastPlataformaProfessorDocumento.tipo_documento"
                  class="form-control"
                >
                  <option
                    v-for="documento in fastSecretariaTipoDocumentos"
                    :key="documento.tipo_documento"
                    :value="documento.tipo_documento"
                  >
                    {{ documento.nome_documento }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccess"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn
                    :attrs="restrictArquivo"
                  >
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirProfessorDocumento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir documento?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirProfessorDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirProfessorDocumento()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEscolherDisciplina"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Escolha uma disciplina</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherDisciplina')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div>
                <vue-tree-list
                  :model="treeDisciplinas"
                  default-tree-node-name="Disciplina"
                  :default-expanded="false"
                  @delete-node="confirmaDisciplinaEscolhida"
                >
                  <template v-slot:leafNameDisplay="slotProps">
                    <span>{{ slotProps.model.name }}</span>
                  </template>
                  <span
                    slot="delNodeIcon"
                    title="Escolher essa disciplina"
                    style="cursor: pointer"
                    class="icon"
                  >
                    <button class="btn btn-sm btn-success pt-0 pb-0">
                      <small>+ Adicionar</small>
                    </button>
                  </span>
                  <span
                    slot="addTreeNodeIcon"
                    title="Escolher essa disciplina"
                    class="icon d-none"
                  />
                  <span
                    slot="addLeafNodeIcon"
                    class="icon d-none"
                  />
                  <span
                    slot="editNodeIcon"
                    title="Editar"
                    class="icon d-none"
                  />
                  <span
                    slot="treeNodeIcon"
                    class="icon"
                  >📚</span>
                </vue-tree-list>

                <div
                  v-if="!treeDisciplinas.children"
                  class="text-center"
                >
                  <h6>Nenhuma disciplina cadastrada</h6>
                  <a
                    :href="
                      '/plataforma/' +
                        $route.params.id_plataforma +
                        '/admin/personalizar-plataforma#disciplinas'
                    "
                    class="btn btn-sm btn-primary"
                  >Cadastrar disciplinas</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "../components/ImageCropperUsuario";
import { VueTreeList, Tree, TreeNode } from "vue-tree-list"; //https://github.com/ParadeTo/vue-tree-list#readme

export default {
  name: "HomeInternoSecretariaProfessor",
  components: {
    VueCropper,
    VueTreeList,
  },
  mixins: [methods],
  data: function () {
    return {
      fastUsuarioLogado: {
        id_usuario: this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario,
      },
      modalWidth: "80%",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Loading da ação
      fastCarregandoAcao: false,
      // Dados pessoais
      fastPessoa: {
        id_usuario: "",
        usuario_cadastro: "",
        email: "",
        first_name: "",
        last_name: "",
        nome_razao: "",
        sobrenome_fantasia: "",
        cpf_cnpj: "",
        datanascimento_abertura: null,
        sexo: "",
        uf: "",
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        complemento: "",
        numero: "",
        telefone: "",
        id_professor: "",
        alteracao_pendente: false,
        loading : true
      },
      // Dados complementares
      fastPlataformaProfessor: {
        id_plataforma: 0,
        id_usuario: "",
        id_professor_concurseiro: 0,
        email: "",
        id_professor: 0,
        licenciatura: "0",
        image: "",
        first_name: "",
        last_name: "",
        carregando: true,
        alteracao_pendente: false,
      },
      fastProfessorImageNova: "",
      // Documentos
      fastPlataformaProfessorDocumento: {
        id_plataforma: 0,
        id_professor: "",
        tipo_documento: "",
        path_documento: "",
        carregando: true,
      },
      fastPlataformaProfessorDocumentoExcluir: {
        id_plataforma: 0,
        id_professor: "",
        path_documento: "",
        path_documento: "",
      },
      fastPlataformaProfessorDocumentos: [],
      // Documentos da plataforma
      fastSecretariaTipoDocumentos: [],
      // Upload arquivo
      optionsArquivo: {
        target:
          settings.endApiFastEad +
          "api/fast_plataforma_professor_documento/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_professor: "",
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Disciplinas
      fastPlataformaProfessorDisciplina: {
        id_professor: "",
        id_plataforma_area_disciplina: "",
        carregando: true,
      },
      fastPlataformaProfessorDisciplinas: [],
      treeDisciplinas: new Tree({
        name: "Primeira disciplina",
        id: 0,
        isLeaf: false,
        addLeafNodeDisabled: true,
      }),
      // Financeiro
      fastPlataformaProfessorFinanceiro: {
        id_plataforma: 0,
        id_professor: "",
        contrato: "",
        valor_hora: "",
        salario_clt_bruto: "",
        valor_contrato: "",
        data_inicio: "",
        data_encerramento: "",
        ativo: "",
        carregando: true,
        alteracao_pendente: false,
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_pessoa) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.buscaInfoPessoaProfessor(this.$route.params.id_pessoa)
            .then(res => {
              res.loading = false
              this.fastPessoa = res
              this.optionsArquivo.query.id_professor = res.id_professor
              this.$store.state.fastCarregando = false
            })
            .then(() => {
              this.getFastPlataformaProfessor(
                this.$route.params.id_plataforma,
                this.$route.params.id_pessoa
              );
              this.getFastPlataformaProfessorDocumento(
                this.$route.params.id_plataforma,
                this.fastPessoa.id_professor
              );
              this.getSecretariaTipoDocumentos(this.$route.params.id_plataforma);
              this.getFastPlataformaProfessorFinanceiro(
                this.$route.params.id_plataforma,
                this.fastPessoa.id_professor
              );
              this.getFastPlataformaProfessorDisciplinas(
                this.fastPessoa.id_professor
              );
              this.getFastDisciplinas();
            })
            .catch(e => {
              this.$store.state.fastCarregando = false
              this.exibeToasty(e, "error")
            })
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Dados pessoais
    async buscaInfoPessoaProfessor(id_pessoa) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_professor/lista_pessoa?id_plataforma=" + 
              this.$route.params.id_plataforma + "&id_pessoa=" + this.$route.params.id_pessoa,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = json[0];
          console.log("buscaInfoPessoa", obj)     
          if (obj.datanascimento_abertura)
            obj.datanascimento_abertura = obj.datanascimento_abertura.split(
              "T"
            )[0];
          else obj.datanascimento_abertura = "";
          if (!obj.nome_razao) obj.nome_razao = "";
          if (!obj.sobrenome_fantasia) obj.sobrenome_fantasia = "";
          if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
          if (!obj.cep) obj.cep = "";
          if (!obj.cidade) obj.cidade = "";
          if (!obj.complemento) obj.complemento = "";
          if (!obj.logradouro) obj.logradouro = "";
          if (!obj.numero) obj.numero = "";
          if (!obj.sexo) obj.sexo = "";
          if (!obj.telefone) obj.telefone = "";
          if (!obj.uf) obj.uf = "";
          if (!obj.bairro) obj.bairro = "";

          if (!obj.id_usuario)
            reject("Não foi possível identificar o Id_usuario. Consulte um administrador do sistema.")
          else if (!obj.id_pessoa)
            reject("Não foi possível identificar o Id_pessoa. Consulte um administrador do sistema.")
          else if (!obj.email)
            reject("Não foi possível identificar o email do usuário. Consulte um administrador do sistema.")
          else if (!obj.id_professor)
            reject("Não foi possível identificar o id_professor. Consulte um administrador do sistema.")
          else 
            resolve(obj)
          
        } catch (e) {
          console.log("Erro", e);
          reject("Erro ao busca pessoa")          
        }

      });
      
    },
    // Busca cep api correio
    async buscaCEPProfessor(){
      if (this.fastPessoa.cep.length == 9) {
        this.buscaCEPCorreio(this.fastPessoa.cep).then(e => {
          if (e.cep){
            this.fastPessoa.cep = e.cep;
            this.fastPessoa.logradouro = e.logradouro;
            this.fastPessoa.bairro = e.bairro;
            this.fastPessoa.cidade = e.cidade;
            this.fastPessoa.uf = e.uf;
          } else {
            this.exibeToasty("CEP não encontrado", "error")
          }
        }).catch(e => this.exibeToasty(e, "error"))
      }      
    },
    async alteraPlataformaPessoa() {
      this.$store.state.fastCarregando = true;
      const ModelPessoa = {
        fast_pessoa: {
          id_usuario: this.fastPessoa.id_usuario,
          id_Pessoa: this.fastPessoa.id_pessoa,
          nome_razao:
            this.fastPessoa.nome_razao,
          sobrenome_fantasia: this.fastPessoa.sobrenome_fantasia,
          cpf_cnpj: this.fastPessoa.cpf_cnpj,
          datanascimento_abertura: this.fastPessoa.datanascimento_abertura,
          sexo: this.fastPessoa.sexo,
          id_plataforma: this.$route.params.id_plataforma,
          payee_code_gerencianet: ""
        },
        fast_usuario: {
          id_usuario: this.fastPessoa.id_usuario
        },
        fast_pessoa_endereco: {
          uf: this.fastPessoa.uf,
          cep: this.fastPessoa.cep,
          logradouro: this.fastPessoa.logradouro,
          bairro: this.fastPessoa.bairro,
          cidade: this.fastPessoa.cidade,
          complemento: this.fastPessoa.complemento,
          numero: this.fastPessoa.numero,
        },
        fast_pessoa_telefone: {
          telefone: this.fastPessoa.telefone,
        },
        id_plataforma: this.$route.params.id_plataforma
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/atualiza_pessoa_secretaria",
          this.fastAjaxOptions("POST", JSON.stringify(ModelPessoa))
        );
        let json = await resp.json();
        this.fastPessoa.alteracao_pendente = false;
        let obj = JSON.parse(json);

        if (!obj.error) {
          if (obj.datanascimento_abertura)
            obj.datanascimento_abertura = obj.datanascimento_abertura.split(
              "T"
            )[0];
          else obj.datanascimento_abertura = "";
          if (!obj.first_name) obj.First_name = "";
          if (!obj.last_name) obj.Last_name = "";
          if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
          if (!obj.cep) obj.cep = "";
          if (!obj.cidade) obj.cidade = "";
          if (!obj.complemento) obj.complemento = "";
          if (!obj.logradouro) obj.logradouro = "";
          if (!obj.numero) obj.numero = "";
          if (!obj.sexo) obj.sexo = "";
          if (!obj.telefone) obj.telefone = "";
          if (!obj.uf) obj.uf = "";
          if (!obj.bairro) obj.bairro = "";
          this.fastPessoa = obj;
        }
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Perfil alterado com sucesso",
        });
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    // Dados do professor
    async getFastPlataformaProfessor(id_plataforma, id_pessoa) {
      this.fastProfessorImageNova = "";
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_professor/lista_pessoa?id_plataforma=" + 
              this.$route.params.id_plataforma + "&id_pessoa=" + this.$route.params.id_pessoa,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = json;
          console.log("fastPlataformaProfessor", obj);
          if (obj.length) {
            this.fastPlataformaProfessor = obj[0];
            this.fastProfessorImageNova = this.fastPlataformaProfessor.image;
          }
        } catch (e) {
          console.log("Erro", e);
          reject("Erro ao buscarr pessoa")          
        }
      });
    },
    async atualizaPlataformaProfessor() {
      this.fastPlataformaProfessor.id_plataforma = this.$route.params.id_plataforma;
      let erros = [];
      if (!this.fastPlataformaProfessor.first_name)
        erros.push("O nome é obrigatório");
      if (!this.fastPlataformaProfessor.last_name)
        erros.push("O sobrenome é obrigatório");
      if (!this.fastPlataformaProfessor.email)
        erros.push("O email é obrigatório");
      if (this.fastProfessorImageNova)
        this.fastPlataformaProfessor.image = this.fastProfessorImageNova;
      if (!this.fastPlataformaProfessor.id_usuario)
        erros.push("ID do usuário não identificado");

      this.fastPlataformaProfessor.id_professor_concurseiro = 0;

      if (!erros.length) {
        this.$store.state.fastCarregando = true;
        this.promiseInserirFastApi(
          this.fastPlataformaProfessor,
          "fast_plataforma_professor"
        )
          .then((res) => {
            console.log("cadastrarProfessor", res);
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Alterações realizadas com sucesso", "success");
            this.getFastPlataformaProfessor(
              this.$route.params.id_plataforma,
              this.fastPessoa.id_professor
            );
          })
          .catch((e) => {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao atualizar professor", "error");
          });
      } else {
        this.$store.state.fastCarregando = false;
        erros.forEach((e) => this.exibeToasty(e, "error"));
      }
    },
    // Documentos
    async getFastPlataformaProfessorDocumento(id_plataforma, id_professor) {
      this.fastPlataformaProfessorDocumento.carregando = true;
      this.promiseGetFastApi(
        "api/fast_plataforma_professor_documento/lista",
        "id_plataforma=" + id_plataforma + "&id_professor=" + id_professor
      )
        .then((obj) => {
          console.log("fastPlataformaProfessorDocumento", obj);
          this.fastPlataformaProfessorDocumento.carregando = false;
          if (obj.length) this.fastPlataformaProfessorDocumentos = obj;
          else this.fastPlataformaProfessorDocumentos = [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSecretariaTipoDocumentos(id_plataforma) {
      this.promiseGetFastApi(
        "api/fast_secretaria_tipo_documento/lista",
        "id_plataforma=" + id_plataforma
      )
        .then((obj) => {
          console.log("getSecretariaTipoDocumentos", obj);
          if (obj.length) {
            this.fastSecretariaTipoDocumentos = obj;
            this.fastPlataformaProfessorDocumento.tipo_documento =
              obj[0].tipo_documento;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fileValidation(e) {
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      console.log("fileSuccess", result);
      if (!result.error) {
        this.fastPlataformaProfessorDocumento.path_documento = result.url;
        this.cadastraProfessorDocumento();
      }
    },
    async cadastraProfessorDocumento() {
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_professor: this.fastPessoa.id_professor,
        tipo_documento: this.fastPlataformaProfessorDocumento.tipo_documento,
        path_documento: this.fastPlataformaProfessorDocumento.path_documento,
      };
      this.$store.state.fastCarregando = true;
      this.promiseInserirFastApi(obj, "fast_plataforma_professor_documento")
        .then((res) => {
          this.$store.state.fastCarregando = false;
          if (res.length) {
            this.exibeToasty("Documento salvo com sucesso", "success");
            this.getFastPlataformaProfessorDocumento(
              this.$route.params.id_plataforma,
              this.fastPessoa.id_professor
            );
            this.hideModal("modalCadastrarProfessorDocumento");
          } else {
            this.exibeToasty("Erro ao salvar documento", "error");
          }
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao salvar documento", "error");
        });
    },
    exibeModalExcluirProfessorDocumento(documento) {
      this.fastPlataformaProfessorDocumentoExcluir = documento;
      this.showModal("modalExcluirProfessorDocumento");
    },
    async excluirProfessorDocumento() {
      this.promiseExcluirFastApi(
        this.fastPlataformaProfessorDocumentoExcluir,
        "fast_plataforma_professor_documento"
      )
        .then((e) => {
          this.exibeToasty("Documento excluído com sucesso", "success");
          this.getFastPlataformaProfessorDocumento(
            this.$route.params.id_plataforma,
            this.$route.params.id_pessoa
          );
          this.hideModal("modalExcluirProfessorDocumento");
        })
        .catch((e) => {
          this.exibeToasty("Erro ao excluir", "error");
          this.$store.state.fastCarregando = false;
        });
    },
    // Disciplina
    async getFastPlataformaProfessorDisciplinas(id_professor) {
      this.fastPlataformaProfessorDisciplina.carregando = true;
      this.promiseGetFastApi(
        "api/fast_plataforma_professor_disciplina/lista",
        "id_professor=" + id_professor
      )
        .then((obj) => {
          console.log("getFastPlataformaProfessorDisciplinas", obj);
          this.fastPlataformaProfessorDisciplina.carregando = false;
          if (obj.length) this.fastPlataformaProfessorDisciplinas = obj;
          else this.fastPlataformaProfessorDisciplinas = [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getFastDisciplinas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_disciplinas/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.name = e.descricao;
            e.id = e.id_plataforma_area_disciplina;
            e.isLeaf = false;
            e.addLeafNodeDisabled = true;
          });
          let arrayDisciplinasPai = obj.filter((d) => {
            if (d.id_plataforma_area_disciplina_pai == "0") return d;
          });
          arrayDisciplinasPai.forEach(function (d, index) {
            verificaNivel2(d, index);
          });

          function verificaNivel2(d, index) {
            obj.forEach(function (d2) {
              if (
                d.id_plataforma_area_disciplina ==
                d2.id_plataforma_area_disciplina_pai
              ) {
                if (!arrayDisciplinasPai[index].children)
                  arrayDisciplinasPai[index].children = [];
                arrayDisciplinasPai[index].children.push(d2);

                verificaNivelUltimos(d2);
              }
            });
          }

          function verificaNivelUltimos(d) {
            obj.forEach(function (d2) {
              if (
                d.id_plataforma_area_disciplina ==
                d2.id_plataforma_area_disciplina_pai
              ) {
                if (!d.children) d.children = [];
                d.children.push(d2);
                verificaNivelUltimos(d2);
              }
            });
          }

          this.treeDisciplinas = new Tree(arrayDisciplinasPai);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    confirmaDisciplinaEscolhida(obj) {
      let fast_plataforma_professor_disciplina = {
        id_professor: this.fastPessoa.id_professor,
        id_plataforma_area_disciplina: obj.id,
      };
      fetch(
        `${settings.endApiFastEad}api/fast_plataforma_professor_disciplina/insere`,
        this.fastAjaxOptions(
          "POST",
          JSON.stringify(fast_plataforma_professor_disciplina)
        )
      )
        .then((resp) => resp.json())
        .then((obj) => {
          this.hideModal("modalEscolherDisciplina");
          this.exibeToasty("Disciplina salva com sucesso", "success");
          this.getFastPlataformaProfessorDisciplinas(
            this.fastPessoa.id_professor
          );
        })
        .catch((e) => {
          console.log(e);
          this.exibeToasty("Erro ao salvar disciplina", "error");
        });
    },
    async excluirDisciplinaProfessor(disciplina) {
      let fast_plataforma_professor_disciplina = {
        id_professor: this.fastPessoa.id_professor,
        id_plataforma_area_disciplina: disciplina.id_plataforma_area_disciplina,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_professor_disciplina/exclui",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_plataforma_professor_disciplina)
          )
        );
        let json = await resp.json();
        this.exibeToasty("Disciplina excluída com sucesso", "success");
        this.getFastPlataformaProfessorDisciplinas(
          this.fastPessoa.id_professor
        );
      } catch (e) {
        console.log("Erro", e);
        this.exibeToasty("Erro ao excluir disciplina", "error");
      }
    },
    // Financeiro
    async getFastPlataformaProfessorFinanceiro(id_plataforma, id_professor) {
      this.fastPlataformaProfessorFinanceiro.carregando = true;
      this.promiseGetFastApi(
        "api/fast_plataforma_professor_financeiro/lista",
        "id_plataforma=" + id_plataforma + "&id_professor=" + id_professor
      )
        .then((obj) => {
          console.log("getFastPlataformaProfessorFinanceiro", obj);
          this.fastPlataformaProfessorFinanceiro.carregando = false;
          if (obj.length) this.fastPlataformaProfessorFinanceiro = obj[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async atualizaPlataformaProfessorFinanceiro() {
      this.fastPlataformaProfessorFinanceiro.id_plataforma = this.$route.params.id_plataforma;
      this.fastPlataformaProfessorFinanceiro.id_professor = this.fastPessoa.id_professor;
      let erros = [];
      if (!this.fastPlataformaProfessorFinanceiro.contrato)
        erros.push("O contrato é obrigatório");
      if (!erros.length) {
        this.$store.state.fastCarregando = true;
        this.promiseInserirFastApi(
          this.fastPlataformaProfessorFinanceiro,
          "fast_plataforma_professor_financeiro"
        )
          .then((res) => {
            console.log("atualizaPlataformaProfessorFinanceiro", res);
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Alterações realizadas com sucesso", "success");
            this.getFastPlataformaProfessorFinanceiro(
              this.$route.params.id_plataforma,
              this.fastPessoa.id_professor
            );
          })
          .catch((e) => {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao atualizar", "error");
          });
      } else {
        this.$store.state.fastCarregando = false;
        erros.forEach((e) => this.exibeToasty(e, "error"));
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
